import React from 'react';
import './Footer.css'; 
import { hover } from '@testing-library/user-event/dist/hover';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container footer-suscribe mt-5 mb-5">
        <div className="footer-title">
          <h2><strong>Besoin d'aide <br /> ou de plus </strong> d'informations ?</h2>
          <p>Tenez-vous au courant des nos activités, grâce à notre newsletter.</p>
        </div>
        <div className="footer-form">
          <form action="#">
            <label className='newsletter' htmlFor="">Newsletter</label>
            <input type="email" placeholder="Votre adresse email" required />
            <button type="submit">S'inscrire</button>
          </form>
        </div>
      </div>
      <hr className="container" />
      <div className="container footer-content">
        <div className="footer-links">
            <div className='d-flex justify-content-between gap-3'>
                <div>
                    <p className=''><a style={{color: '#0FACCF'}} className='text-decoration-none fs-6'  href="/about">A propos</a></p>
                    <a style={{color: '#0FACCF'}} className='text-decoration-none fs-6' href="">carriere</a>
                  </div>
                  <div>
                  <p className=''><a style={{color: '#0FACCF'}} className='text-decoration-none fs-6'  href="#">carrière</a></p>
                  <a style={{color: '#0FACCF'}} className='text-decoration-none fs-6' href="#">Contact</a>
                </div>
            </div>
        </div>
        <div className="footer-social">
          <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f"></i></a>
          <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a>
          <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin-in"></i></a>
          <a href="https://www.x.com" target="_blank" rel="noopener noreferrer"><i className="fab fa-x"></i></a>
          <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer"><i className="fab fa-youtube"></i></a>
          <a href="https://www.whatsapp.com" target="_blank" rel="noopener noreferrer"><i className="fab fa-whatsapp mb-1"></i></a>
          <p className='fs-6'>&copy; Data360degree 2024. Tous droits réservés.</p>
          <div className="">
            <a className='text-decoration-none fs-6' href="#">Politique de confidentialité</a>|
            <a className='text-decoration-none fs-6' href="#">Conditions d'utilisation</a>
        </div>
        </div>
      </div>
    </footer>
  );
}; 
export default Footer;
