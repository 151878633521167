import React from 'react';
// import Image3 from '../../assets/images/image3.jpg'
import vision from '../../assets/images/Mission.png'

const TabContent3 = () => {
    return  <div>
    <section className="d-flex-justify-content section-tabs">
        <div className="description-tabs">
            <div>
                <h2>Prise de décision basé sur l’effet et non par coût de tête</h2>
            </div>
            <div className=''>
                <p className='mb-4'>
                    Notre mission est de vous offrir des solutions de données de pointe, 
                    conçues pour vous permettre de prendre des décisions éclairées et basées 
                    sur des faits concrets. Nous nous engageons à vous accompagner dans 
                    l'atteinte de vos objectifs, en transformant des données brutes en informations 
                    stratégiques qui propulsent votre succès.
                </p>
            </div>
        </div>
        <figure className='image'>
        <img className='images-tabs' src={vision} alt=""/>
        </figure>
    </section>
</div>;
};

export default TabContent3