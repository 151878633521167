import React, { useState } from "react";
import { FaPlus, FaMinus } from "react-icons/fa";
import './Faq.css'; // Assurez-vous que le fichier CSS est bien importé

const FAQ = () => {
  const faqData = [
    {
      question: "Comment utiliser ce site ?",
      answer: "Pour utiliser ce site, il vous suffit de naviguer à travers les différentes sections via le menu."
    },
    {
      question: "Comment puis-je créer un compte ?",
      answer: "Cliquez sur le bouton 'S'inscrire' en haut à droite et suivez les instructions."
    },
    {
      question: "Puis-je changer mes informations de profil ?",
      answer: "Oui, vous pouvez modifier vos informations de profil en allant dans la section 'Mon compte'."
    },
  ];

  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    if (openIndex === index) {
      setOpenIndex(null);
    } else {
      setOpenIndex(index);
    }
  };

  return (
    <div className="faq-container">
      {faqData.map((faq, index) => (
        <div key={index} className="faq-item">
          <div 
            className="faq-question" 
            onClick={() => toggleFAQ(index)}
          >
            <span>{faq.question}</span>
            <span>
              {openIndex === index ? <FaMinus /> : <FaPlus />} {/* Icône dynamique */}
            </span>
          </div>
          {openIndex === index && (
            <div className="faq-answer">
              {faq.answer}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default FAQ;
