import React from 'react';
import './Economics.css';
import OptiminationImg from '../../../assets/images/hero.png';


function Economics() {
  return (
    <div>
        <div className='objectif'>
            <div className='objectif__description'>
              <div className='container'>
                <p>Notre objectif est de comprendre les dynamiques démographiques et les caractéristiques de la population humaines en République Démocratique du Congo et dans la région.</p>
              </div>
            </div>
            <div className='objectif__content'>
              <div className='container d-flex justify-content-between'>
                  <div className='objectif__desc'>
                      <div className='objectif__content_title mb-3'>
                          <h3>Récheche démographique</h3>
                      </div>
                      <div className='objectif__content_description'>
                          <p>Nous étudions la populations humaines de leur taille, de leur structure par âge et par sexe, de leur répartition géographique, de leur croissance et de leurs mouvements.</p>
                      </div>
                      <div className='objectif__content_list'>
                          <ul>
                            <li><i class="fa-regular fa-check-circle"></i> Naissance</li>
                            <li><i class="fa-regular fa-check-circle"></i> Décès</li>
                            <li><i class="fa-regular fa-check-circle"></i> Migrations</li>
                          </ul>
                      </div>
                      <div className='global__buttons objectif__content_buttons'>
                          <button>Demandez une consultation</button>
                          <button>Télécharger Brochure</button>
                      </div>
                  </div>
                  <div className='objectif__image'>
                      <img src={OptiminationImg} alt="" />
                  </div>
                </div>
            </div>
        </div>
        <div className='health'>
            <div className="container d-flex  justify-content-between">
                <div className='health_image'>
                  <img src={OptiminationImg} alt="" />
                </div>
                <div className='health_content'>
                    <div className='health_content_title'>
                      <h3>Santé publique :</h3>
                    </div>
                    <div className='health_content_description'>
                        <p>Nous étudions la santé des populations et des facteurs qui l'influencent. Nous nous intéressons notamment aux inégalités en matière de santé, aux déterminants sociaux de la santé et aux politiques de santé publique.</p>
                    </div>
                    <div className='global__buttons health_content_buttons'>
                      <button>Demandez une consultation</button>
                      <button>Télécharger Brochure</button>
                    </div>
                </div>
            </div>
        </div>
        <div className='economic'>
              <div className="container d-flex justify-content-between">
                  <div className='economic__content'>
                      <div className='economic__content_title'>
                          <h3>Économie de la population</h3>
                      </div>
                      <div className='economic__content_title'>
                        <p>
                          Nous étudions des liens entre les variables économiques et les phénomènes démographiques. Nous examinons aussi les effets des politiques économiques sur la croissance démographique, l'emploi, les revenus, etc.
                        </p>
                      </div>
                      <div className='global__buttons economic__content_buttons'>
                          <button>Demandez une consultation</button>
                          <button>Télécharger Brochure</button>
                      </div>
                  </div>
                  <div className='econmic__image'>
                    <img src={OptiminationImg} alt="" />
                  </div>
              </div>
        </div>
        <div className='population'>
            <div className='container d-flex justify-content-between'>
                <div className='population__image'>
                  <img src={OptiminationImg} alt="" />
                </div>
                <div className='population__content'>
                    <div className='population__content_title'>
                      <h3>Géographie de la population</h3>
                    </div>
                    <div className='population__content_description'>
                        <p>Nous analysons la répartition spatiale des populations, des facteurs qui influencent cette répartition et des conséquences géographiques des phénomènes démographiques.</p>
                    </div>
                    <div className='global__buttons population__content_buttons'>
                      <button>Demandez une consultation</button>
                      <button>Télécharger Brochure</button>
                    </div>
                </div>
            </div>
        </div>
        <div className='Anthropologie'>
            <div className='container d-flex justify-content-between'>
                <div className='Anthropologie__content'>
                    <div className='Anthropologie__content_title'>
                      <h3>Anthropologie de la population</h3>
                    </div>
                    <div className='Anthropologie__contentent_description'>
                      <p>Nous étudions des populations sous l'angle culturel et historique. En s'intéressant aux systèmes de valeurs, aux croyances, aux pratiques sociales et aux représentations qui influencent les comportements démographiques.</p>
                    </div>
                    <div className='global__buttons Anthropologie__contentent_buttons'>
                        <button>Demandez une consultation</button>
                        <button>Télécharger Brochure</button>
                    </div>
                </div>
                <div className='Anthropologie__image'>
                  <img src={OptiminationImg} alt="" />
                </div>
            </div>
        </div>
        <div className='Sociologie'>
            <div className='container d-flex justify-content-between'>
                <div className='Sociologie__image'>
                  <img src={OptiminationImg} alt="" />
                </div>
                <div className='Sociologie__content'>
                    <div className='Sociologie__content_title'>
                        <h3>Sociologie de la population:</h3>
                    </div>
                    <div className='Sociologie__content_description'>
                        <p>Nous analysons des relations entre les phénomènes démographiques et les processus sociaux, culturels et économiques. Elle s'intéresse notamment aux comportements reproductifs, aux modes de vie, aux inégalités sociales et à leur impact sur la dynamique démographique.</p>
                    </div>
                    <div className='global__buttons Sociologie__content_buttons'>
                        <button>Demandez une consultation</button>
                        <button>Télécharger Brochure</button>
                    </div>
                </div>
            </div>
        </div>
        {/* <section className='know-more'> 
            <div className='know-more__title'>
                                <h4>En savoir plus sur nos offres</h4>
            </div>
            <div className='know-more_description'>
                                <p>Pour en savoir plus sur nos services ou pour discuter de vos besoins spécifiques, n'hésitez pas à nous contacter.</p>
            </div>
            <div className='know-more__button'>
                                <button>Contactez-nous</button>
            </div>
        </section> */}
          <section className='offer'>
              <div className="container">
                    <div className='offer_title mb-2'>
                        <h2>En savoir plus sur nos offres</h2>
                    </div>
                    <div className='offer_content mb-3'>
                        <p>
                        Pour en savoir plus sur nos services ou pour discuter de vos besoins spécifiques, 
                        n'hésitez pas à nous contacter.
                        </p>
                    </div>
                    <div className='offer_btn'>
                        <button>Contactez-nous</button>
                    </div>
                  </div>
            </section>
    </div>
  );
}

export default Economics;
