// src/components/Modal.js
import React from 'react';
import './Modal.css';

function Modal({ isOpen, onClose }) {
    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-container"> 
            <div className='modal-desc'>
                <span>Contactez-nous</span>
                <h2>Trouvez la solution adaptée à votre entreprise</h2>
                <p>Dans un monde en constante évolution, <br /> 
                nous sommes là pour vous aider à garder une <br /> 
                longueur d'avance avec les outils permettant de mesurer,  <br />
                de vous connecter et d'engager votre public.</p>
            </div>
            <div className="modal-content">
                <button className="close-button" onClick={onClose}>
                    &times;
                </button>
                <form>
                    <div className="input-group">
                        <div>
                        <label>
                            Nom de famille *:
                            <input placeholder='Nom de famille' type="text" name="nom" />
                        </label>
                        </div>
                        <div>
                        <label>
                            Prénom *:
                            <input placeholder='Prénom' type="text" name="prenom" />
                        </label>
                        </div>
                    </div>
                    <label>
                        Adresse e-mail profetionnelle *:
                        <input placeholder='Email' type="email" name="email" />
                    </label>
                    <div className="input-group">
                        <div>
                        <label>
                            Entreprise *:
                            <input placeholder="Par exemple: 'data360degre'" type="text" name="nom" />
                        </label>
                        </div>
                        <div>
                        <label>
                            Industrie *:
                            <select name="industrie" id="industrie-select">
                                <option value="industrie">Industrie</option>
                                <option value="industrie">Industrie</option>
                                <option value="industrie">Industrie</option>
                            </select>
                        </label>
                        </div>
                    </div>
                    <label>
                        Commentaire:
                        <textarea className='textarea' placeholder='(limite 255 caractère)' name="message"></textarea>
                    </label>
                    <span>255 sur 255 Caractère(s) restant</span>

                    {/* <label htmlFor="">
                        <input type="" name="accept-terms" />
                         J'accepte les <a href="https://www.data360degre.com/conditions-generales-d-utilisation/">termes et conditions
                         </a>
                    </label>
                    <label htmlFor="">
                        <input type="checkbox" name="accept-terms" /> J'accepte les <a href="https://www.data360degre.com/mentions-legales/">mentions legales</a>
                    </label> */}

                    <button type="submit">Envoyer</button>
                </form>
            </div>
        </div>
        </div>
    );
}

export default Modal;
