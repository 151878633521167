import React from 'react';
import './Marketing.css';
import OptiminationImg from '../../../assets/images/Besoin.png';
import analyse from '../../../assets/images/Analyse.png';

function Marketing() {
    return (
            <div className=''>
                    <div className='optimisation'>
                            <div className='optimisation__description'>
                                <div className='container'>
                                    <p>
                                        Optimisez votre performance grâce à nos méthodes modernes et dynamiques, conçues pour <strong>s'adapter aux marchés en constante évolution</strong>
                                    </p>
                                </div>
                            </div>
                            <div className='optimisation__bloc'>
                                <div className='container'>
                                    <div className='optimisation__bloc_content'>
                                        <div className='optimisation__content_title'>
                                            <h3>Afin de répondre avec précisions à vos besoins.</h3>
                                        </div>
                                        <div className='optimisation__content_description'>
                                            <p>ous personnalisons vos projet d’étude de marché en nous concentrant sur quatre aspects clés :</p>
                                        </div>
                                        <div className='optimisation__list'>
                                            <ul>
                                                <li><i class="fa-regular fa-check-circle"></i> Le marché,</li>
                                                <li><i class="fa-regular fa-check-circle"></i> L'offre,</li>
                                                <li><i class="fa-regular fa-check-circle"></i> La démande,</li>
                                                <li><i class="fa-regular fa-check-circle"></i> L'environement des affaires</li>
                                            </ul>
                                        </div>
                                        <div className='optimisation__buttons marketing__buttons button mt-5'>
                                            <button>Démandez une consultation</button>
                                            <button>Télécharger une brochure</button>
                                        </div>
                                    </div>
                                    <div className='optimisation__bloc_image'>
                                        <img src={OptiminationImg} alt="" />
                                    </div>
                                </div>
                            </div>
                    </div>
                    <div className='analyse'>
                        <div className='container d-flex justify-content-between'>
                            <div className='analyse__image'>
                                <img src={ analyse} alt="" />
                            </div>
                            <div className='analyse__content'>
                                <div className='analyse__title'>
                                    <h3>Grâce à nos analyses</h3>
                                </div>
                                <div className='analyse__description'>
                                    <p>Nous vous offrons une vision plus claire d'évolution de votre produit</p>
                                </div>
                                <div className='analyse__list'>
                                    <ul>
                                        <li><i class="fas fa-check"></i> en identifiant ses tendances actuelles,</li>
                                        <li><i class="fas fa-check"></i> en évaluant sa part de marché ,</li>
                                        <li><i class="fas fa-check"></i> en déterminant sa part de développement,</li>
                                        <li><i class="fas fa-check"></i> en évaluants les risques et les opportunités, </li>
                                        <li><i class="fas fa-check"></i> en identifiant les barières</li>
                                    </ul>
                                </div>
                                <div className='analyse__description'>
                                    <p>Afin d’elaoborer une stratégie marketing efficace, en fournissant des données précieuses pour le positionnement de vos produits et services.</p>
                                </div>
                                <div className='analyse__buttons marketing__buttons'>
                                    <button className='analyse__buttons-first'>Démandez une consultation</button>
                                    <button className='analyse__buttons-second'>Télécharger une brochure</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className='methode'>
                        <div className='container'>
                            <div className='methode__title mb-3'>
                                <h4>Nos approches méthodologies sont adaptable selon la nature du problème</h4>
                            </div>
                            <div className='methode__cards'>
                                <article className='methode__cards_card'>
                                    <div className='methode__card_icon'>
                                        <button>
                                            <i class="fas fa-chart-bar"></i>
                                        </button>
                                    </div>
                                    <div className='methode__card_title'>
                                        <h5>Quantitative</h5>
                                    </div>
                                    <div className='methode__card_description'>
                                        <p>Mesurer et décrire les attitudes, 
                                            les opinions et les comportements d'un échantillon représentatif en
                                            utilisant des méthodes quantitatives. Les données sont collectées 
                                            à travers une diversité de modes d'enquête : face-à-face (PAPI), 
                                            téléphonique (CATI), assisté par ordinateur (CAPI), mobile (CAMI), 
                                            en ligne (CAWI) ou en salle (CLT).</p>
                                    </div>
                                </article>
                                <article className='methode__cards_card-color'>
                                    <div className='methode__card_icon'>
                                        <button>
                                            <i class="fas fa-edit"></i>
                                        </button>
                                    </div>
                                    <div className='methode__card_title'>
                                        <h5>Quanlitative</h5>
                                    </div>
                                    <div className='methode__card_description'>
                                        <p>comprendre en profondeur les perceptions, les opinions et les comportements d'un segment de population ciblé à travers des focus groups, des entretiens individuels, des séances de brainstorming, des immersions consommateurs et des ethnographies.</p>
                                    </div>
                                </article>
                                <article className='methode__cards_card'>
                                    <div className='methode__card_icon'>
                                        <button>
                                            <i class="fas fa-file-alt"></i> 
                                        </button>
                                    </div>
                                    <div className='methode__card_title'>
                                        <h5>Documentaire</h5>
                                    </div>
                                    <div className='methode__card_description'>
                                        <p>Afin d'explorer une problématique sous tous ses angles ou de poser les bases d'une étude approfondie, nous procédons à une collecte exhaustive d'informations préexistantes en consultant diverses sources telles que les archives, les rapports, les publications officielles, Internet et les experts.</p>
                                    </div>
                                </article>
                            </div>
                        </div>
                    </section>
                    <section className='quality'>
                        <div className='container'>
                            <div className='quality__title mb-3'>
                                <h4>Processus de contrôle qualité des nos étude quantitative & Qualitative</h4>
                            </div>
                            <div className='quality__cards'>
                                <article className='quality__cards_card'>
                                    <div className='quality__cards_card-icon'>
                                        <i></i>
                                    </div>
                                    <div className='quality__cards_card-title'>
                                        <h5>Contrôle qualité pour une étude quantitative</h5>
                                    </div>
                                    <div className='quality__cards_card-description'>
                                        <p>Un contrôle qualité rigoureux est mis en place à chaque étape de l'enquête. Ce contrôle inclut une supervision directe de 10% des entretiens, une vérification ponctuelle de 25% des questionnaires, des rappels téléphoniques pour 20% des entretiens, l'enregistrement audio de 25% des entretiens et une vérification systématique de la durée des entretiens. Ces mesures permettent d'assurer la fiabilité et la qualité des données recueillies.</p>
                                    </div>
                                </article>
                                <article className='quality__cards_card'>
                                    <div className='quality__cards_card-icon'>
                                        <i></i>
                                    </div>
                                    <div className='quality__cards_card-title'>
                                        <h5>Contrôle qualité pour une étude qualitative</h5>
                                    </div>
                                    <div className='quality__cards_card-description'>
                                        <p>Afin de garantir la fiabilité des données recueillies, nous mettons en place un processus de contrôle qualité rigoureux. Celui-ci couvre l'ensemble du processus, de la conception des guides de recrutement jusqu'à l'analyse des notes finales. Chaque étape vise à s'assurer que les participants sont bien ciblés, que les interactions sont de qualité et que les données sont complètes et précises.</p>
                                    </div>
                                </article>
                            </div>
                            <div className='marketing__buttons quality__button'>
                                <button >Télécharger une brochure</button>
                            </div>
                        </div>
                    </section>
                    {/* <section className='know-more'>
                            <div className='know-more__title'>
                                <h4>En savoir plus sur nos offres</h4>
                            </div>
                            <div className='know-more_description'>
                                <p>Pour en savoir plus sur nos services ou pour discuter de vos besoins spécifiques, n'hésitez pas à nous contacter.</p>
                            </div>
                            <div className='know-more__button'>
                                <button>Contactez-nous</button>
                            </div>
                    </section> */}
                    <section className='offer'>
                        <div className='container'>
                            <div className='offer_title mb-2'>
                                <h2>En savoir plus sur nos offres</h2>
                            </div>
                            <div className='offer_content mb-3'>
                                <p>
                                Pour en savoir plus sur nos services ou pour discuter de vos besoins spécifiques, 
                                n'hésitez pas à nous contacter.
                                </p>
                            </div>
                            <div className='offer_btn'>
                                <button>Contactez-nous</button>
                            </div>
                        </div>
                    </section>
            </div>  
    );
}

export default Marketing;
