import React from 'react';
import event from '../../assets/images/hero.jpg'
import './Event.css'
const TabContent2 = () => {
    return  <div>
                <div className='last-event'>
                    <div className='img-last'>
                        <img className='img__last-img' src={event} alt="" />
                    </div>&nbsp;
                    <div className='title-last'>
                        <h6>Publié | 11 mars 2022</h6>
                        <h2>Méthodologies de recherche</h2>
                        <p>We explore why brands need to understand where and how to invest to earn the good will of the LGBTQ+ community and…</p>
                    </div>
                </div>
                <div className='last-article__button mb-5'>
                    <button className='last-article__button-voirplus'>Voir tous les evénements</button>
                </div> 
                <hr className='article-insgth-hr' />
            </div>
};

export default TabContent2