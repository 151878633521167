import React from 'react';
// import Image2 from '../../assets/images/image2.jpg'
import vision from '../../assets/images/Value.png'


const TabContent2 = () => {
    return  <div>
    <section className="d-flex-justify-content section-tabs">
        <div className="description-tabs">
            <div className='description_titles'>
                <h2>Le IIEE socle de nos valeurs pour bouster vos entreprises</h2>
            </div>
            <div className='description_list'>
                <ul>
                    <li>
                        <i class="fas fa-handshake icon"></i>
                        <strong>Confiance :</strong> Fournir des services de qualité et de confiance.
                    </li>

                    <li>
                        <i class="fas fa-shield-alt icon"></i>
                        <strong>Intégrité :</strong> Agir avec honnêteté et transparence dans toutes nos interactions.
                    </li>
                    <li>
                        <i class="fas fa-star icon"></i>
                        <strong>Excellence :</strong> S'engager à fournir des services de qualité et à respecter les délais.
                    </li>
                    <li>
                        <i class="fas fa-users icon"></i>
                        <strong>Engagement : </strong>Être pleinement engagé dans la réussite de nos clients et dans le
                        développement de notre continent.
                    </li>
                </ul>
            </div>
        </div>
        <figure className='image'>
            <img className='images-tabs' src={vision} alt=""/>
        </figure>
    </section>
</div>;
};

export default TabContent2