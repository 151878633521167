import React, { useRef } from 'react';
import profil from '../../assets/images/p.png';
// import '.TeamSection'

const TeamSection = () => {
  const cardContainerRef = useRef(null);

  const scrollLeft = () => {
    cardContainerRef.current.scrollBy({ left: -300, behavior: 'smooth' });
  };

  const scrollRight = () => {
    cardContainerRef.current.scrollBy({ left: 300, behavior: 'smooth' });
  };

  return (
    <div className='team'>
      <div className='team__title'>
        <h3 className='mb-3'>Notre équipe</h3>
      </div>
      <div className='team_text'>
        <p>
          Nos équipes, composées de plus d'une centaine d'enquêteurs 
          sur le terrain et d'experts multidisciplinaires, travaillent en étroite 
          collaboration pour mener à bien nos missions.
        </p>
      </div>
      <div className='team__wrapper'>
        <button className='team__scroll-btn team__scroll-btn--left' onClick={scrollLeft}>
          ◀ {/* Icône flèche gauche */}
        </button>
        
        <div className='team__card-container' ref={cardContainerRef}>
          <div className='team__card'>
            <div className='team_bloc_image'>
              <img src={profil} alt="John Doe" />
            </div>
            <div className='team_bloc_title'>
              <h4>John Doe</h4>
              <h6>Fondateur & CEO</h6>
            </div>
            <div className='team_bloc_desc'>
              <p>Expert Data Scientist</p>
            </div>
            <div className='team_bloc_btn'>
              <button>En savoir plus</button>
            </div>
          </div>
          <div className='team__card'>
            <div className='team_bloc_image'>
              <img src={profil} alt="John Doe" />
            </div>
            <div className='team_bloc_title'>
              <h4>John Doe</h4>
              <h6>Fondateur & CEO</h6>
            </div>
            <div className='team_bloc_desc'>
              <p>Expert Data Scientist</p>
            </div>
            <div className='team_bloc_btn'>
              <button>En savoir plus</button>
            </div>
          </div>
          <div className='team__card'>
            <div className='team_bloc_image'>
              <img src={profil} alt="John Doe" />
            </div>
            <div className='team_bloc_title'>
              <h4>John Doe</h4>
              <h6>Fondateur & CEO</h6>
            </div>
            <div className='team_bloc_desc'>
              <p>Expert Data Scientist</p>
            </div>
            <div className='team_bloc_btn'>
              <button>En savoir plus</button>
            </div>
          </div>
          <div className='team__card'>
            <div className='team_bloc_image'>
              <img src={profil} alt="John Doe" />
            </div>
            <div className='team_bloc_title'>
              <h4>John Doe</h4>
              <h6>Fondateur & CEO</h6>
            </div>
            <div className='team_bloc_desc'>
              <p>Expert Data Scientist</p>
            </div>
            <div className='team_bloc_btn'>
              <button>En savoir plus</button>
            </div>
          </div>
          <div className='team__card'>
            <div className='team_bloc_image'>
              <img src={profil} alt="John Doe" />
            </div>
            <div className='team_bloc_title'>
              <h4>John Doe</h4>
              <h6>Fondateur & CEO</h6>
            </div>
            <div className='team_bloc_desc'>
              <p>Expert Data Scientist</p>
            </div>
            <div className='team_bloc_btn'>
              <button>En savoir plus</button>
            </div>
          </div>

          {/* Ajoute d'autres cards ici */}
        </div>

        <button className='team__scroll-btn team__scroll-btn--right' onClick={scrollRight}>
          ▶ {/* Icône flèche droite */}
        </button>
      </div>
    </div>
  );
};

export default TeamSection;
