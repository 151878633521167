import React, { useState } from 'react';
import './Solution.css'; 
import SolutionImg from '../../assets/images/Image.png';
import Marketing from './Marketing/Marketing';
import Economics from './Economics/Economics';
import DataConsulting from './DataConsulting/DataConsulting';
import StrategicWatch from './StrategicWatch/StrategicWatch';

function Solution() {
  const [activeCard, setActiveCard] = useState('marketing');

  const renderContent = () => {
    switch (activeCard) {
      case 'marketing':
        return <Marketing />;
      case 'economics':
        return <Economics />;
      case 'dataConsulting':
        return <DataConsulting />;
      case 'strategicWatch':
        return <StrategicWatch />;
      default:
        return <Marketing />;
    }
  };

  return (
      <div className='solution'>
          <div className="solution__hero">
            <div className='container d-flex justify-content-between'>
                <div className="solution__hero-text d-flex justify-content-start align-items-center">
                  <h1>Solution</h1>
                </div>
                <div className="solution__hero-img">
                  <img src={SolutionImg} alt="image service" />
                </div>
              </div>
          </div>
        <div className='solution-card'>
          <div className='container'>
            <div className="service_title">
                <h2>Produits & Services</h2>
            </div>
          </div>
          <div className='solution__section-card mt-5'>
            <div className='container d-flex justify-content-center gap-3'>
                <div 
                  className={`solution__card-block ${activeCard === 'marketing' ? 'active' : ''}`} 
                  onClick={() => setActiveCard('marketing')}
                >
                  <h2 className='solution__title-card1'>Recherche marketing</h2>
                  <p>La recherche marketing est essentielle pour comprendre votre marché, anticiper les tendances et prendre des décisions éclairées...</p>
                  <button className={`mb-3 ${activeCard === 'marketing' ? 'active-button' : ''}`}>Explorer</button>
                </div>

                <div 
                  className={`solution__card-block ${activeCard === 'economics' ? 'active' : ''}`} 
                  onClick={() => setActiveCard('economics')}
                >
                  <h2 className='solution__title-card2'>Science de population</h2>
                  <p>Ce champ de recherche englobe une multitude de disciplines, dont la démographie, la sociologie, l'économie, la géographie...</p>
                  <button className={`mb-3 ${activeCard === 'economics' ? 'active-button' : ''}`}>Explorer</button>
                </div>

                <div 
                  className={`solution__card-block ${activeCard === 'dataConsulting' ? 'active' : ''}`} 
                  onClick={() => setActiveCard('dataConsulting')}
                >
                  <h2 className='solution__title-card3'>DATA Consulting</h2>
                  <p>Les données sont au cœur de la prise de décision moderne. Nous vous aidons à exploiter pleinement la puissance de vos données  grâce</p>
                  <button className={`mb-3 ${activeCard === 'dataConsulting' ? 'active-button' : ''}`}>Explorer</button>
                </div>

                <div 
                  className={`solution__card-block ${activeCard === 'strategicWatch' ? 'active' : ''}`} 
                  onClick={() => setActiveCard('strategicWatch')}
                >
                  <h2 className='solution__title-card4'>Veille Stratégique</h2>
                  <p>Surveillez votre environnement d'affaires et restez informé des  tendances et des évolutions qui peuvent impacter votre entreprise...</p>
                  <button className={`mb-3 ${activeCard === 'strategicWatch' ? 'active-button' : ''}`}>Explorer</button>
                </div>
              </div>
          </div>
        </div>

        <div className='solution__content'>
          {renderContent()}
        </div>
      </div>
  );
}

export default Solution;