import React from 'react';
import article from '../../assets/images/blog.jpg'
import './Article.css'

const TabContent1 = () => {
    return  (
            <div>
                <div className='last-article'>
                    <div className='img-last'>
                        <img className='img__last-img' src={article} alt="" />
                    </div>&nbsp;
                    <div className='title-last'>
                        <h6>Publié | 11 mars 2022</h6>
                        <h2>Méthodologies de recherche</h2>
                        <p>We explore why brands need to understand where and how to invest to earn the good will of the LGBTQ+ community and…</p>
                    </div>
                </div>
                <div className='last-article__button'>
                    <button className='last-article__button-voirplus'>Voir les autres articles</button>
                </div>
                <hr className='article-insgth-hr' />
            </div>
    );
}

export default TabContent1