import React from 'react';
import './DataConsulting.css'
import AI from '../../../assets/images/robo.png';
import imgmanagement from '../../../assets/images/management.png';

function DataConsulting() {
  return (
    <div className='conten-global'>
      <div className='management'>
            <div className='management__title mb-3'>
              <h2>Gestion des données et Analytics </h2>
            </div>
            <div className='management__description'>
              <p>Nous exploitons le potentiel des données pour vous offrir des insights précieux et exploitables. Notre équipe d'experts utilise des techniques avancées de traitement et d'analyse des données pour répondre à vos besoins spécifiques.</p>
            </div>
        <div className="container d-flex justify-content-between">
            <div className='management__bloc'>
              <div className='management__bloc_image'>
                <img src={imgmanagement} alt="" />
              </div>
              <div className='management__bloc_content'>
                <div className='management__bloc_content-title mb-3'>
                  <h4>Utilisation de techniques analytiques avancées pour transformer vos données en insights exploitables.</h4>
                </div>
                <div className='management__bloc_content-list mb-3'>
                  <ul>
                    <li><strong>Collecte et Intégration des Données : </strong>Nous vous aidons à collecter, à centraliser, à organiser, à structurer et à intégrer vos données provenant de diverses sources pour une vue complète et cohérente de vos opérations.
                    </li>
                    <li><strong>Création de pipelines de données : </strong>Automatisation du traitement et du stockage des données.</li>
                    <li>
                        <strong>Traitement et Analyse des Données : </strong>Nettoyage, transformation et utilisation de techniques analytiques avancées tel que les analyses prédictive et descriptive pour transformer vos données en insights exploitables.
                    </li>
                    <li>
                        <strong>Visualisation des Données : </strong>Création de tableaux de bord interactifs et de rapports visuels pour faciliter la compréhension et la communication des résultats.
                    </li>
                    <li>
                        <strong>Prédiction de données : </strong>Modélisation et anticipation des tendances futures.
                    </li>
                    <li>
                      <strong>Création de produits de données : </strong>Développement de solution innovantes pour exploiter vos données
                    </li>
                    <li>
                      <strong>Géstion de données : </strong>Stratégies des gouvernances de donnés pour assurer la qualité, la sécurité, et la conformité des donnés
                    </li>
                  </ul>
                </div>
              </div>
          </div>
        </div>
        <div className='global__buttons management__buttons'>
          <button>Demandez une consultation</button>
          <button>Télécharger Brochure </button>
        </div>
      </div>
      <div className='inteligence'>
            <div className='container d-flex justify-content-between'>
          <div className='inteligence__content'>
                <div className='inteligence__content_title'>
                  <h2>Intelligence artificielle</h2>
                </div>
                <div claasName='inteligence__content_description'>
                  <p>Intégrez l'intelligence artificielle dans vos opérations pour automatiser les processus, améliorer l'efficacité et stimuler l'innovation. </p>
                </div>
                <div className='inteligence__content_description'>
                  <p>Nous développons des solutions d'IA sur mesure adaptées à votre secteur et à vos objectifs.</p>
                </div>
                <div className='inteligence__content_list'>
                    <ul>
                      <li><i class="fas fa-check"></i> Apprentissage automatique <span>(Machine learning)</span></li>
                      <li><i class="fas fa-check"></i> Traitement du langage naturel <span>(NLP)</span></li>
                      <li><i class="fas fa-check"></i> Reconnaissance d'image et de voix</li>
                      <li><i class="fas fa-check"></i> Chatbots et assistance virtuel</li>
                    </ul>
                </div>
                <div className='global__buttons inteligence__content_buttons'>
                  <button>Demandez une consultation</button>
                  <button>Télécharger Brochure </button>
                </div>
              </div>
              <div className="inteligence__image">
                  <img src={AI} alt="" />
              </div>
          </div>
      </div>
      <section className='consultation'>
        <div className="container">
            <div className="consultation__title mb-3">
              <h2>Consulting & integration</h2>
            </div>
          <div className='consultation__description'>
            <p>Profitez de l'expertise de nos consultants pour optimiser votre stratégie de données et Assurez-vous que vos systèmes de données fonctionnent <br></br> en harmonie.</p>
          </div>
        </div>
        <div className='container'>
            <div className="consultation__cards">
              <article className="card">
                <div className="card_btn-icon mb-3">
                  <button>
                  <i class="fas fa-chart-pie"></i>
                  </button>
                </div>
                <div className="card_title mb-3">
                  <h4>Consulting en Data Science</h4>
                </div>
                <div className="card_description">
                  <p>Nous vous aidons à identifier les opportunités, à résoudre les défis et à mettre en œuvre des solutions durables.</p>
                </div>
                <div className="card_list">
                  <ul>
                    <li><i class="fas fa-check"></i> Stratégie de données </li>
                    <li><i class="fas fa-check"></i> Gouvernance de données</li>
                    <li><i class="fas fa-check"></i> Transformation digital</li>
                    <li><i class="fas fa-check"></i> Formation et accompagnement</li>
                  </ul>
                </div>
              </article>
              <article className='card'>
                <div className='card_btn-icon mb-3'>
                  <button>
                  <i class="fas fa-cog"></i>
                  </button>
                </div>
                <div className='card_title mb-3'>
                  <h4>Integration de système</h4>
                </div>
                <div className='card_description'>
                  <p>Nous intégrons vos différentes sources de données pour créer une infrastructure cohérente et efficace.</p>
                </div>
                <div className='card_list'>
                  <ul>
                    <li><i class="fas fa-check"></i> Integration de base de données</li>
                    <li><i class="fas fa-check"></i> Migration de données</li>
                    <li><i class="fas fa-check"></i> Solution cloud et hybrides</li>
                    <li><i class="fas fa-check"></i> API et connecteurs personnalisés</li>
                  </ul>
                </div>
              </article>
            </div>
          </div>
      </section>
      <section className='offer'>
        <div className='container'>
            <div className='offer_title mb-2'>
                                <h2>En savoir plus sur nos offres</h2>
                            </div>
                            <div className='offer_content mb-3'>
                                <p>
                                Pour en savoir plus sur nos services ou pour discuter de vos besoins spécifiques, 
                                n'hésitez pas à nous contacter.
                                </p>
                            </div>
                            <div className='offer_btn'>
                                <button>Contactez-nous</button>
                            </div>
            </div>
      </section>
    </div>
  );
}

export default DataConsulting;
