import React, { useState, useEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import '../../App.css';
import '../../global.css';
import TabContent1 from '../../components/TabContent1';
import TabContent2 from '../../components/TabContent2';
import Hero1 from '../../assets/images/hero.png';
import data from '../../assets/images/drowp-menu.png';
import pub from '../../assets/images/close-up-woman-being-surprised.jpg';
import Hero2 from '../../assets/images/violet.png';
import motemaImage from '../../assets/images/motema.png';
import Orange from '../../assets/images/orang.png';
import Voda from '../../assets/images/voda.png';
// import Cloud from '../../assets/images/cloud.png';
import Cls from '../../assets/images/logo.png';
import { Link } from 'react-router-dom';

const heroData = [
    {
        h1: (  
            <>Nous croyons en la puissance des données...</>  
        ),  
        description: 'Notre mission est de vous fournir les outils et les analyses nécessaires pour maximiser vos performances et prendre des décisions éclairées.',
        imageUrl: Hero1,
        backgroundColor: '#0faccf',
        button: 'En savoir plus',
    },
    {
        h1: (  
            <>Nous aspirons à être le partenaire de confiance...</>  
        ),  
        description: "Notre objectif est de rendre les données accessibles et exploitables pour tous nos clients, quel que soit leur secteur d'activité ou leur taille. ",
        imageUrl: Hero2,
        backgroundColor: '#7f0783',
        button: 'En savoir plus',
    }
];
function Home() {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % heroData.length);
        }, 10000); // Change every 10 seconds

        return () => clearInterval(interval);
    }, []);

    const currentHero = heroData[currentIndex];
    const [activeTab, setActiveTab] = useState('tab1');

    return (
        <main>
                <section 
                    className="full-width-bg hero-section" 
                    style={{ backgroundColor: currentHero.backgroundColor }}
                >
                    <div className="container d-flex justify-content-between">
                        <div className="hero-desc">
                            <h1>{currentHero.h1}</h1> 
                            <p>{currentHero.description}</p>
                            <button>{currentHero.button}</button> 
                        </div>
                        <figure className="hero-imgs">
                            <img src={currentHero.imageUrl} alt="Hero" />
                        </figure>
                    </div>
                </section>
              {/* Section 2 tabs */}
                <section className="full-width-bg second-section">
                    <div className='container'>
                        <div className="tabs">
                        <div className="tab-buttons">
                            <button onClick={() => setActiveTab('tab1')} className={activeTab === 'tab1' ? 'active' : ''}>
                            Articles récents
                            </button>&nbsp;&nbsp;
                            <button onClick={() => setActiveTab('tab2')} className={activeTab === 'tab2' ? 'active' : ''}>
                            Evénements 
                            </button>&nbsp;&nbsp;
                        </div>
                        <div className="tab-content">
                            <TransitionGroup>
                            <CSSTransition key={activeTab} timeout={300} classNames="fade">
                                {activeTab === 'tab1' ? <TabContent1 /> : activeTab === 'tab2' ? <TabContent2 /> : null}
                            </CSSTransition>
                            </TransitionGroup>
                        </div>
                        </div>
                    </div>
                </section>
            
                <div className='container hero2-section'>
                    <div className='hero2-desc'>
                        <span>Nos méthodologies de recherche</span>
                        <h3>Data360degree</h3>
                        <p>Découvrez comment nos solutions innovantes peuvent propulser <br />votre entreprise vers de nouveaux sommets.</p>
                        <Link to={'/solutions'}>
                            <button>Apprendre encore plus</button>
                        </Link>
                    </div>
                    <figure className='hero2-img'>
                        <img src={data} alt="" />
                    </figure>
                </div>
                <div>
                <div className='section-card'>
                    <div className='container d-flex justify-content-between gap-3'>
                        <div className='card-block'>
                            <h2 className='title-card1'>Recherche marketing</h2>
                            <p>La recherche marketing est essentielle pour comprendre votre marché, anticiper les tendances et prendre des décisions éclairées...</p>
                            <Link style={{ textDecoration: 'none'}} className='d-flex justify-content-center' to="/solutions">
                                <button className='mb-3'>Explorer</button>
                            </Link>
                        </div>
                        <div className='card-block'>
                            <h2 className='title-card2'>Science de population</h2>
                            <p>Ce champ de recherche englobe une multitude de disciplines, dont la démographie, la sociologie, l'économie, la géographie...</p>
                            <Link style={{ textDecoration: 'none'}}  className='d-flex justify-content-center' to='/solutions'>
                                <button className='mb-3'>Explorer</button>
                            </Link>
                        </div>
                        <div className='card-block data'>
                            <h2 className='title-card3'>DATA Consulting </h2>
                            <p>Les données sont au cœur de la prise de décision moderne. Chez DATA360, nous vous aidons à exploiter pleinement la puissance de vos données grâce à nos solutions...</p>
                            <Link style={{ textDecoration: 'none'}}  className='d-flex justify-content-center' to="/solutions">
                                <button className='mb-3'>Explorer</button>
                            </Link>
                        </div>
                        <div className='card-block strategic'>
                            <h2 className='title-card4'>Veille Stratégique</h2>
                            <p>Surveillez votre environnement d'affaires et restez informé des tendances et des évolutions qui peuvent impacter votre entreprise...</p>
                            <Link  style={{ textDecoration: 'none'}} className='d-flex justify-content-center' to='/solutions'>
                            <   button className='mb-3'>Explorer</button>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className='btn-container mb-5'>
                    <Link to={'/solutions'}>
                        <button>Voir toute les solutions</button>
                    </Link>
                    </div>
                </div>

                <section className="pub-section mb-5">
                    <div className="container title">
                        <h5 className='text-white'>Veille stratégique</h5>
                        <h2 className='pub-section-title'>Nous mettons a votre disposition NUNGA SCAN MEDIAS, 
                        une plateforme intelligente de veille médiatique, veille concurencielle, 
                        veille technologique et de pige.
                        </h2>
                    </div>
                    <div className="container pub-container">
                        <div className="pub-desc">
                            <span className=''>NUNGA scan médias</span>
                            <h2 className='mt-4'>Obtenez des rapports personalisé sur la veille Stratégique</h2>
                            <h6>Surveillez votre environnement d'affaires et restez informé des tendances et des évolutions qui peuvent impacter votre entreprise.</h6>
                            <p>Nous mettons a votre service une veille stratégique pour une prise de décision proactive et efficace graâce à nos rappports hébdomadaire, mensuel et annuel.</p>
                        </div>
                        <figure>
                            <img src={pub} alt="" />
                        </figure>
                    </div>
                    <div>
                        {/* <button>Voir toute les informations</button> */}
                    </div>
                </section>
            
            <section className="section-personalize mb-5" >
                <div className='container title-personalize mb-5'>
                    <span className='mt-5'>Nos réalisations</span>
                    <h2>Nous sommes au cœur d’un changement positif au sein des entreprises, rendu possible grâce à nos produits et services innovants.</h2>
                </div>
                <hr className='container'/>
                <div className='container container-personalize mt-1'>
                    <div className='block-750'>
                        <h2 >+ 372</h2>                        
                    </div>
                    <div>
                        <h3>
                            Recherches marketing déjà <br /> réalisé pour nos clients en RDC <br /> et à la sous-régions
                        </h3>
                    </div>
                    <div className='block-text'>
                        <p> Nos études sont réalisées selon diverses méthodologies : enquêtes auprès de ménages, études de terrain, enquêtes en ligne et par téléphone, ainsi que des études par panels représentatifs.
                        </p>
                    </div>
                    <div className='block-button'>
                        <button>Voir plus</button>
                    </div>
                </div>
                <hr className='container'/>
                <div className='container container-personalize'>
                    <div className='block-750'>
                        <h2 className='mt-3'>+ 130 </h2>                        
                    </div>
                    <div>
                        <h3>
                            Etudes démographique, <br /> économiques et sanitaire  
                        </h3>
                    </div>
                    <div className='block-text'>
                        <p>
                        Nous réalisons des études auprès de ménages et de communautés locales, en appliquant des protocoles d'enquête conformes aux meilleures pratiques scientifiques.
                        </p>
                    </div>
                    <div className='block-button'>
                        <button>Voir plus</button>
                    </div>
                </div>
                <hr className='container' />
                <div className='container container-personalize'>
                    <div className='block-750'>
                        <h2 className='mt-3'>+ 17  </h2>                        
                    </div>
                    <div>
                        <h3>
                            Data Consuslting réalisé <br /> pour nos partenaires au pays<br /> 
                        </h3>
                    </div>
                    <div className='block-text'>
                        <p>
                        Grâce à nos expertises en science des données et en développement d'applications et d'infrastructures informatiques, nous accompagnons les entreprises dans la mise en œuvre de stratégies data-driven et data-centric.
                        </p>
                    </div>
                    <div className='block-button'>
                        <button>Voir plus</button>
                    </div>
                </div>
                <hr className='container' />
                <div className='container container-personalize'>
                    <div className='block-750'>
                        <h2 className='mt-3'>+ 70 </h2>                        
                    </div>
                    <div>
                        <h3>
                            Clients régulier ce <br /> sont abonnées à notre <br /> veille médiatique<br /> 
                        </h3>
                    </div>
                    <div className='block-text'>
                        <p>
                        Soutenus par de vraies
                        personnes,
                        nos panels mondiaux 
                        fournissent une validation critique 
                        des données et garantissent <br /> 
                        la représentativité.
                        </p>
                    </div>
                    <div className='block-button'>
                        <button>Voir plus</button>
                    </div>
                </div>
            </section>
                {/* Section des Partenaires */}
            <section className="partners">
                    <h2>Nos Partenaires</h2>
                <div className='container d-flex justify-content-center'>
                    <div className="partners-container">
                    <div className="partner">
                    <img  src={motemaImage} alt="Logo du partenaire 1" />
                    </div>
                    <div className="partner">
                    <img src={Cls} alt="Logo du partenaire 2" />          
                    </div>
                    <div className="partner">
                    <img src="" alt="Logo du partenaire 3" />          
                    </div>
                    <div className="partner">
                    <img src={Voda} alt="Logo du partenaire 4" />          
                    </div>
                    <div className="partner">
                    <img src={Orange} alt="Logo du partenaire 5" />
                    </div>
                    </div>
                </div>
            </section>
                </main>
                
    );
}

export default Home;
