import React from 'react';
// import Image1 from '../../assets/images/image1.jpg'
import vision from '../../assets/images/vision.png'

const TabContent1 = () => {
    return  <div>
                <div className="d-flex-justify-content section-tabs">
                    <div className="description-tabs">
                        <h2>Nous croyons en la puissance des données pour transformer les entreprises.</h2>
                        <p className='mb-4'>
                        Depuis notre création, nous nous engageons à fournir des solutions de données innovantes et adaptées aux besoins spécifiques de nos clients. Notre expertise couvre un large éventail de 
                        domaines allant de la recherche marketing à la collecte et l'analyse de données économiques, démographiques et sanitaires.
                        </p>
                    </div>
                    <figure className='image'>
                        <img className='images-tabs' src={vision} alt=""/>
                    </figure>
                </div>
            </div>
};

export default TabContent1